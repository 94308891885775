import './App.sass';
import HeaderImage from './assets/images/header.png';
import Pin from './assets/images/pin.png';
import Icon1 from './assets/images/icon1.png';
import Icon2 from './assets/images/icon2.png';
import Icon3 from './assets/images/icon3.png';
import Divider from './assets/images/divider.png';
import Mozak from './assets/images/mozak.png';
import Bsd from './assets/images/bsd.png';
import { useState } from 'react';
import DocumentMeta from "react-document-meta";
import InputMask from 'react-input-mask';
import Cta from './components/Cta';
import Modal from './components/Modal';

function App() {
  return (
    window.location.href = "https://marismozak.com.br/"
  );
  
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [checkbox, setCheckbox] = useState(false);

  // const meta = {
  //   title: 'Leblon - Mozak',
  //   description: 'Lançamento Leblon - Mozak',
  //   // canonical: 'http://example.com/path/to/page',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: 'mozak,leblon,empreendimento,lançamento,comercial,luxo,imobiliária,imóveis,imobiliárias,imobiliárias no rio de janeiro,imóveis no rio de janeiro,imóveis no rio,imóvel'
  //     }
  //   }
  // };

  // function openModal() {
  //   document.querySelector('.modal').style.display = 'flex';
  // }

  // function checkData(e) {

  //   if (checkbox) {


  //     if (name != "" && email != "" && phone != "") {
  //       sendData(name, email, phone);
  //     } else {
  //       alert('Preencha todos os campos antes de enviar');
  //     }

  //   } else {
  //     alert('Preencha o checkbox antes de enviar');
  //   }

  // }

  // function getMediaFromURL() {
  //   var url = window.location.href;
  //   var allParams = url.split('?');

  //   if (allParams.length < 2) {
  //     return 'Direct'
  //   }

  //   var params = allParams[1].split('&');

  //   var source, utm_source, utm_medium, utm_campaign;

  //   if (params.find(element => element.includes('gclid')) != undefined) {
  //     // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
  //     return 'Google Ads';
  //   }

  //   // if(params.find(element => element.includes('posicionamento')) != undefined){
  //   //   source = params.find(element => element.includes('posicionamento')).split('=')[1];
  //   //   return source
  //   // }

  //   if (params.find(element => element.includes('utm_source')) != undefined) {
  //     source = params.find(element => element.includes('utm_source')).split('=')[1];
  //     return source;
  //   }


  //   return 'Landing page';
  // }

  // function sendData(name, email, phone) {
  //   var midia = getMediaFromURL();
  //   fetch('https://mozak.hypnobox.com.br/email.receber.php', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       nome: name,
  //       email: email,
  //       tel_celular: phone,
  //       id_produto: 34,
  //       midia: midia
  //     })
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       alert('Dados enviados com sucesso!');
  //       setName("");
  //       setEmail("");
  //       setPhone("");
  //       setCheckbox(false);
  //       window.location.reload();
  //     })
  // }

  // const scrollTo = () => {
  //   const element = document.getElementById("maris-content");
  //   element.scrollIntoView({behavior: "smooth"});
  // }

  // const activeLabel = (e) => {
  //   e.target.previousElementSibling.classList.add("active");
  // }

  // const blurLabel = (e) => {
  //   if(e.target.value === "") {
  //     e.target.previousElementSibling.classList.remove("active");
  //   }
  // }

  // return (
  //   <div className="maris">
  //     <DocumentMeta {...meta} />

  //     <img className="mozak" src={Mozak} alt="mozak" />
  //     <img className="bsd" src={Bsd} alt="bsd" />

  //     <header className="maris-header">
  //       <img className="header-image" src={HeaderImage} alt="" />

  //       <section>
  //         <h1>INVISTA NO SEU NOVO ESPAÇO DE TRABALHO EM MEIO A UM RESPIRO URBANO NO LEBLON</h1>

  //         <h2><img src={Pin} alt="pin" /> Rua Conde de Bernadotte, 55</h2>

  //         <div className="data">

  //           <div className="data-content">

  //             <p className="data-first">ESPAÇOS COMERCIAIS <br /> ENTRE 17 M² E 67 M²</p>

  //             <div className="divider"></div>

  //             <p className="data-second">Curadoria artística <br/> Design inédito <br /> Lounge externo</p>

  //           </div>
            
            
  //         </div>

  //         <p className="header-text">O comercial idealizado para quem busca o transitar fluido entre liberdade, trabalho e o estilo vibrante da vida carioca.</p>
          
  //         <div className="button-content">
  //           <button onClick={scrollTo}>SAIBA MAIS</button>
  //         </div>
  //       </section>

  //     </header>


  //     <section id="maris-content" className="maris-content">
  //       <h2 className="content-headline">Localização privilegiada que te conecta ao melhor do Leblon</h2>

  //       <div className="icons">
          
  //         <div className="icon">
  //           <img src={Icon1} alt="icon1" />
  //           <p className="icon-text-first">Metrô Antero de Quental</p>
  //           <p>5 minutos</p>
  //         </div>

  //         <div className="icon">
  //           <img src={Icon2} alt="icon2" />
  //           <p className="icon-text-first">Praia do Leblon</p>
  //           <p>10 minutos</p>
  //         </div>

  //         <div className="icon">
  //           <img src={Icon3} alt="icon3" />
  //           <p className="icon-text-first">Polo Gastronômico</p>
  //           <p>2 minutos</p>
  //         </div>

  //       </div>

        
  //       <img className="divider" src={Divider} alt="divider" />        


  //       <h2 className="second-headline">VIVA A ESSÊNCIA DO LEBLON TRADUZIDA EM UM COMERCIAL SURPREENDENTE</h2>

  //       <div className="content">
          
  //         <div className="video">
  //           <iframe  src="https://www.youtube.com/embed/PCRT0dnJdcM" title="MOZAK MARIS VÍDEO LP Com abertura" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  //         </div>
          
  //         <div className="form">
  //           <h3>Cadastre-se para mais informações exclusivas</h3>

  //           <div className="input-content">
  //             <label>Nome</label>
  //             <input type="text" onClick={activeLabel} onBlur={blurLabel} onChange={(e) => setName(e.target.value)} />
  //           </div>
            
  //           <div className="input-content">
  //             <label >Email</label>
  //             <input type="text" onClick={activeLabel} onBlur={blurLabel} onChange={(e) => setEmail(e.target.value)} />
  //           </div>
            
  //           <div className="input-content">
  //             <label>Telefone</label>
  //             <InputMask type='text' mask="(99) 99999-9999" onChange={(e) => setPhone(e.target.value)} onClick={activeLabel} onBlur={blurLabel} />
  //           </div>
            
  //           <div className="input-content-checkbox">
  //             <input type="checkbox" id="checkbox" onChange={(e) => setCheckbox(e.target.checked)} />
  //             <label htmlFor="checkbox">Li e aceito os <span onClick={openModal}>termos de uso</span></label>
  //           </div>

  //           <div className="button-content">
  //             <button onClick={checkData}>ENVIAR</button>
  //           </div>


  //         </div>
  //       </div>
  //     </section>
  //     <Modal />
  //     <Cta />
  //   </div>
  // );
}

export default App;
